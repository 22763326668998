/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { ChildCare, RingVolume, PersonPin, Widgets, CloudDownload } from "@material-ui/icons";


// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
	const classes = useStyles();
	return (
		<List className={classes.list}>
			<ListItem className={classes.listItem}>
				<Link style={{ padding: 0 }} to="/" className={classes.dropdownLink}>
					<Button variant="contained" color="transparent" className={classes.navLink} >
						<h5 style={{ padding: 0,margin:0 }}><strong><PersonPin className={classes.icons} style={{width:"20px",height:"20px",marginBottom:"3px"}} />健康管理服务</strong></h5>
					</Button>
				</Link>
			</ListItem>

			<ListItem className={classes.listItem}>
				<Link style={{ padding: 0 }} to="/canhappy.cn/product" className={classes.dropdownLink}>
					<Button variant="contained" color="transparent" className={classes.navLink} >
						<h5 style={{ padding: 0,margin:0 }}><strong><Widgets className={classes.icons} style={{width:"20px",height:"20px",marginBottom:"3px"}} />健康产品中心</strong></h5>
					</Button>
				</Link>
			</ListItem>

			<ListItem className={classes.listItem}>
				<Link style={{ padding: 0 }} to="/canhappy.cn/login-page" className={classes.dropdownLink}>
					<Button variant="contained" color="transparent" className={classes.navLink} >
						<h5 style={{ padding: 0,margin:0 }}><strong><CloudDownload className={classes.icons} style={{width:"20px",height:"20px",marginBottom:"3px"}} />管理登录</strong></h5>
					</Button>
				</Link>
			</ListItem>


			<ListItem className={classes.listItem}>
				<Link style={{ padding: 0 }} to="/canhappy.cn/landing-page" className={classes.dropdownLink}>
					<Button variant="contained" color="transparent" className={classes.navLink} >
						<h5 style={{ padding: 0,margin:0 }}><strong><ChildCare className={classes.icons} style={{width:"20px",height:"20px",marginBottom:"3px"}} />AGI效率工具</strong></h5>
					</Button>
				</Link>
			</ListItem>



			<ListItem className={classes.listItem}>
				<Button variant="contained" color="transparent" className={classes.navLink} >
					<h5 style={{ padding: 0,margin:0 }}><RingVolume className={classes.icons} style={{width:"20px",height:"20px",marginBottom:"3px"}} />TEL:010-80129311</h5>
				</Button>
			</ListItem>
		</List>
	);
}
